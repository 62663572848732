export const getInitialFieldConfig = (placeholder) => ({
    items: [],
    model: "",
    placeholder,
    disabled: true,
    loading: false,
});

const removeSpaces = (value) => value.replace(/\s*/gm, "");

export const getOptionsFromResponse = (valuePropName, labelPropName) => (item) => ({
    value: item[valuePropName],
    label: item[labelPropName],
});

export const resetField = (value) => {
    value.items = [];
    value.model = "";
    value.disabled = true;
};

export const getModelFromFieldObject = (field) => {
    return field?.model || {};
};

export const getOptionsConfigBySelectedApplicationTypes = ({ optionsConfig, type }) => {
    if (!type?.value) return;
    const typesArray = removeSpaces(type.value).split(",");
    const configKey = Object.keys(optionsConfig).find((ids) => {
        const idsArray = `${ids}`.split(",");
        return typesArray.some((type) => idsArray.includes(type));
    });

    return configKey ? optionsConfig[configKey] : null;
};

export const PAGE_BRAND_TAG_PROPERTY = "brandcodepart";

export const getBrandsFromTags = (tags) => {
    if (tags) {
        const tagsArray = tags.split(",");
        const brandTags = tagsArray.reduce((acc, item) => {
            if (item.includes(PAGE_BRAND_TAG_PROPERTY)) {
                const brand = item.split(":")[1];
                return `${acc ? acc + "," : acc}${brand}`;
            }

            return acc;
        }, "");

        return brandTags || "";
    }
    return "";
};
