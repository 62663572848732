<template>
    <div :class="[
        'driv-diagram-results-carousel',
        {
            'corporate': isCorporate,
        },
    ]">
        <vue-slick-carousel class="driv-diagram-results-carousel__nav-container" v-bind="navSettings"
            v-if="carouselImages.length">
            <div v-for="(item, index) in carouselImages" :key="item.file_name"
                :class="['slide', { 'selected-slide': selectedSlideIndex === index }]" @click="() => onSlideSelect(index)">
                <div class="card-container">
                    <div class="slide-image" v-html="item.svg"></div>
                    <span class="title" v-if="item.desc" :title="item.desc" v-html="item.desc"></span>
                    <button class="button-main">{{ btnText(index) }}</button>
                </div>
            </div>
        </vue-slick-carousel>
        <div :class="['driv-diagram-results-carousel__images-container', {
            'selected-image': slideClicked
        }]">
           
           <div v-if="isBrandDefined">
                <button v-if="showExpandButton" class="view-as-fullscreen" @click="() => openFullScreen()">
                   <span>Click to Enlarge Diagram</span>
                </button>
            </div>
            <div class="mobileLabel">
                {{ getTranslation('(Rotate to landscape orientation for an improved experience.)')}}
           </div>
            <button class="close-button" @click="() => exitFullScreen()">
                <i class="fa fa-close"></i>
            </button>
            <div v-html="selectedSlide.svg" :key="selectedSlide.file_name" class="image" :id="selectedSlide.file_name"
                @click="(e) => toggleModal(e, selectedSlide.file_name)"></div>
        </div>
    </div>
</template>

<script>
// import svgPanZoom from 'svg-pan-zoom';
import VueSlickCarousel from "vue-slick-carousel";
import { getTranslation } from "../../../../common/partFinderCorporate.helpers";

const CAROUSEL_REFS = {
    NAV: "nav",
};

export default Vue.component("driv-diagram-results-carousel", {
    components: { VueSlickCarousel },
    data() {
        return {
            navSettings: {
                ref: CAROUSEL_REFS.NAV,
                asNavFor: null,
                slidesToShow: 5,
                arrows: true,
                dots: true,
                responsive: [
                    {
                        breakpoint: 1600,
                        settings: {
                            slidesToShow: 4,
                        },
                    },
                    {
                        breakpoint: 1300,
                        settings: {
                            slidesToShow: 3,
                        },
                    },
                    {
                        breakpoint: 1025,
                        settings: {
                            slidesToShow: 2,
                        },
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            arrows: false,
                        },
                    },
                ],
            },
            selectedSlideIndex: null,
            slideClicked: false,
            isCorporate: window.brand === 'corporate' ? true : false,
            showExpandButton: false,
            currentFile: '',
            panInstance: ''
        };
    },
    computed: {
        selectedSlide() {
            return this.carouselImages[this.selectedSlideIndex] || {};
        },

        isBrandDefined() {
            return (window.brand == 'walker') || (window.brand == 'corporate') || (window.brand == 'moog');
        },
    },
    props: {
        carouselImages: {
            type: Array,
            default: () => [],
        },
        toggleModal: {
            type: Function,
            default: () => { },
        },
        handleDiagramSelect: {
            type: Function,
            default: () => { },
        },
    },
    methods: {
        getTranslation,
        btnText(index) {

            if (this.selectedSlideIndex === index) {
                this.showExpandButton = true;
            }

            return this.selectedSlideIndex === index
                ? getTranslation("selected")
                : getTranslation("select");
        },
        onSlideSelect(id) {
            this.selectedSlideIndex = id;
            this.$nextTick(function () {
                setTimeout(() => {
                    if (this.selectedSlide.file_name) {
                        this.currentFile = this.selectedSlide.file_name;
                        this.handleDiagramSelect(this.selectedSlide.file_name);
                    }
                }, 100); // adjust the delay as needed
            });
        },
        exitFullScreen() {
            this.slideClicked = false;
            document.body.classList.remove('modal-open');
        },
        openFullScreen() {
            document.body.classList.add('modal-open');
            this.slideClicked = true;
        }
    },
});
</script>
