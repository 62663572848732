var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "driv-diagram-results-carousel",
        {
          corporate: _vm.isCorporate
        }
      ]
    },
    [
      _vm.carouselImages.length
        ? _c(
            "vue-slick-carousel",
            _vm._b(
              { staticClass: "driv-diagram-results-carousel__nav-container" },
              "vue-slick-carousel",
              _vm.navSettings,
              false
            ),
            _vm._l(_vm.carouselImages, function(item, index) {
              return _c(
                "div",
                {
                  key: item.file_name,
                  class: [
                    "slide",
                    { "selected-slide": _vm.selectedSlideIndex === index }
                  ],
                  on: {
                    click: function() {
                      return _vm.onSlideSelect(index)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "card-container" }, [
                    _c("div", {
                      staticClass: "slide-image",
                      domProps: { innerHTML: _vm._s(item.svg) }
                    }),
                    _vm._v(" "),
                    item.desc
                      ? _c("span", {
                          staticClass: "title",
                          attrs: { title: item.desc },
                          domProps: { innerHTML: _vm._s(item.desc) }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("button", { staticClass: "button-main" }, [
                      _vm._v(_vm._s(_vm.btnText(index)))
                    ])
                  ])
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            "driv-diagram-results-carousel__images-container",
            {
              "selected-image": _vm.slideClicked
            }
          ]
        },
        [
          _vm.isBrandDefined
            ? _c("div", [
                _vm.showExpandButton
                  ? _c(
                      "button",
                      {
                        staticClass: "view-as-fullscreen",
                        on: {
                          click: function() {
                            return _vm.openFullScreen()
                          }
                        }
                      },
                      [_c("span", [_vm._v("Click to Enlarge Diagram")])]
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "mobileLabel" }, [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.getTranslation(
                    "(Rotate to landscape orientation for an improved experience.)"
                  )
                ) +
                "\n       "
            )
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "close-button",
              on: {
                click: function() {
                  return _vm.exitFullScreen()
                }
              }
            },
            [_c("i", { staticClass: "fa fa-close" })]
          ),
          _vm._v(" "),
          _c("div", {
            key: _vm.selectedSlide.file_name,
            staticClass: "image",
            attrs: { id: _vm.selectedSlide.file_name },
            domProps: { innerHTML: _vm._s(_vm.selectedSlide.svg) },
            on: {
              click: function(e) {
                return _vm.toggleModal(e, _vm.selectedSlide.file_name)
              }
            }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }