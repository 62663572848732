import { getApplicationTypesOptionsFromResponse } from "../engine-search/helpers";
import { APPLICATION_TYPE_KEYS } from "../partFinderCorporate.constants";
import { FIELDS, INITIAL_FIELDS_CONFIG, EMPTY_ITEM } from "./constants";
import {
    getOptionsConfigBySelectedApplicationTypes,
    getOptionsFromResponse,
    resetField,
} from "./helpers";

import { isGPI } from "../partFinderCorporate.helpers";

const VALUE_PROPERTY = "id";
const LABEL_PROPERTY = "value";
const { LOOK_UP_IDS } = Vue.PartFinder;

const resetDependentFields = (field) => {
    const config = getOptionsConfigBySelectedApplicationTypes({
        optionsConfig,
        type: initialField.model,
    });
    const fieldNames = Object.keys(config.fields);
    const dependentFieldsStart = fieldNames.indexOf(field);
    const dependentFieldNames = fieldNames.slice(dependentFieldsStart);

    dependentFieldNames.forEach((field) => {
        resetField(fieldsConfig[field]);
    });

    searchButton.isDisabled = true;
};

const resolveNextDependentFields = (currentField, subBrand) => {
    if (fieldsConfig[currentField]?.model?.value) {
        const fieldValue = fieldsConfig[currentField].model;
        const config = getOptionsConfigBySelectedApplicationTypes({
            optionsConfig,
            type: initialField.model,
        });

        config.fields[currentField].onInput(fieldValue, subBrand, true);
    }
};

const resolveDependentFields = (field) => (value, subBrand, includeDependentFieldsItems) => {
    if (fieldsConfig[field]?.model) {
        !includeDependentFieldsItems && resetDependentFields(field);
    }
    fieldsConfig[field].disabled = false;

    FIELDS_ACTIONS[field](value, subBrand, includeDependentFieldsItems);
};

const enableSearchButton = () => {
    searchButton.isDisabled = false;
};

const setModelAndEmitAction = (field, resolveNextDeps) => {
    fieldsConfig[field].model = fieldsConfig[field].items[0];
    const config = getOptionsConfigBySelectedApplicationTypes({
        optionsConfig,
        type: initialField.model,
    });

    config.fields[field].onInput(fieldsConfig[field].items[0], null, resolveNextDeps);
};

/* Handlers */

const onApplicationTypeInput = (type, subBrand, includeDependentFieldsItems) => {
    const config = getOptionsConfigBySelectedApplicationTypes({ optionsConfig, type });

    if (config) {
        config.onInput(type, subBrand, includeDependentFieldsItems);
        return;
    }

    if (type && !Number(type.value)) {
        window.location.assign(type.value);
    }
};

// Sets focus to the next input element

const setFocusToDropdown = (fieldKey) => { 
    setTimeout(() => {
        const eleContainer = document.querySelector('.' + fieldKey);
        const isvalueSelected = eleContainer.querySelector('.selected-tag');
        if(isGPI() && !isvalueSelected) {
            eleContainer.querySelector('input').focus();
        }
    }, 0);
}

/* Get data */

const getMakes = (value, subBrand, resolveNextDeps) => {
    const typeId = initialField.model.value;
    const yearId = value?.value;

    fieldsConfig[FIELDS.MAKE].loading = true;

    Vue.CatalogApi.CatalogApiService.getMakes({ typeId, yearId, subBrand })
        .then((res) => {
            if (res.length === 1) {
                fieldsConfig[FIELDS.MAKE].model = res[0];
                fieldsConfig[FIELDS.MAKE].onInput(res[0], subBrand, resolveNextDeps);
            } else {
                fieldsConfig[FIELDS.MAKE].items = res[FIELDS.MAKE].map(
                    getOptionsFromResponse(VALUE_PROPERTY, LABEL_PROPERTY),
                );
                resolveNextDeps && resolveNextDependentFields(FIELDS.MAKE, subBrand);
            }
        })
        .catch(() => {
            fieldsConfig[FIELDS.MAKE].items = [];
        })
        .finally(() => {
            fieldsConfig[FIELDS.MAKE].loading = false;
            setFocusToDropdown(FIELDS.MAKE);
        });
};

const getYears = (value, subBrand, resolveNextDeps) => {
    const typeId = value?.value;

    fieldsConfig[FIELDS.YEAR].loading = true;

    Vue.CatalogApi.CatalogApiService.getYears({ typeId, subBrand })
        .then((res) => {
            if (res.length === 1) {
                fieldsConfig[FIELDS.YEAR].model = res[0];
                fieldsConfig[FIELDS.YEAR].onInput(res[0], subBrand, resolveNextDeps);
            } else {
                fieldsConfig[FIELDS.YEAR].items = res[FIELDS.YEAR].map(
                    getOptionsFromResponse(VALUE_PROPERTY, LABEL_PROPERTY),
                );
                resolveNextDeps && resolveNextDependentFields(FIELDS.YEAR, subBrand);
            }
        })
        .catch(() => {
            fieldsConfig[FIELDS.YEAR].items = [];
        })
        .finally(() => {
            fieldsConfig[FIELDS.YEAR].loading = false;
            setFocusToDropdown(FIELDS.YEAR);
        });
};

const getModels = (value, subBrand, resolveNextDeps) => {
    const typeId = initialField.model.value;
    const yearId = fieldsConfig[FIELDS.YEAR].model.value;
    const makeId = value?.value;

    fieldsConfig[FIELDS.MODEL].loading = true;

    Vue.CatalogApi.CatalogApiService.getModels({
        typeId,
        yearId,
        makeId,
        subBrand,
    })
        .then((res) => {
            if (res.length === 1) {
                fieldsConfig[FIELDS.MODEL].model = res[0];
                fieldsConfig[FIELDS.MODEL].onInput(res[0], subBrand, resolveNextDeps);
            } else {
                fieldsConfig[FIELDS.MODEL].items = res[FIELDS.MODEL].map(
                    getOptionsFromResponse(VALUE_PROPERTY, LABEL_PROPERTY),
                );
                resolveNextDeps && resolveNextDependentFields(FIELDS.MODEL, subBrand);
            }
        })
        .catch(() => {
            fieldsConfig[FIELDS.MODEL].items = [];
        })
        .finally(() => {
            fieldsConfig[FIELDS.MODEL].loading = false;
            setFocusToDropdown(FIELDS.MODEL);
        });
};

const getVehicleTypes = (value, _, resolveNextDeps) => {
    const typeId = value?.value;

    fieldsConfig[FIELDS.VEHICLE].loading = true;

    Vue.CatalogApi.CatalogApiService.getVehicleTypes({ typeId })
        .then((res) => {
            fieldsConfig[FIELDS.VEHICLE].items = res[FIELDS.VEHICLE].map(
                getOptionsFromResponse(VALUE_PROPERTY, LABEL_PROPERTY),
            );

            if (res[FIELDS.VEHICLE].length === 1) {
                setModelAndEmitAction(FIELDS.VEHICLE, resolveNextDeps);
            } else if (resolveNextDeps) {
                resolveNextDependentFields(FIELDS.VEHICLE);
            }
        })
        .catch(() => {
            fieldsConfig[FIELDS.VEHICLE].items = [];
        })
        .finally(() => {
            fieldsConfig[FIELDS.VEHICLE].loading = false;
            setFocusToDropdown(FIELDS.VEHICLE);
        });
};

const getManufacturers = (value, _, resolveNextDeps) => {
    const vehicleId = value?.value;

    fieldsConfig[FIELDS.MANUFACTURER].loading = true;

    Vue.CatalogApi.CatalogApiService.getManufacturers({ vehicleId })
        .then((res) => {
            fieldsConfig[FIELDS.MANUFACTURER].items = res[FIELDS.MANUFACTURER].map(
                getOptionsFromResponse(VALUE_PROPERTY, LABEL_PROPERTY),
            );

            if (res[FIELDS.MANUFACTURER].length === 1) {
                setModelAndEmitAction(FIELDS.MANUFACTURER, resolveNextDeps);
            } else if (resolveNextDeps) {
                resolveNextDependentFields(FIELDS.MANUFACTURER);
            }
        })
        .catch(() => {
            fieldsConfig[FIELDS.MANUFACTURER].items = [];
        })
        .finally(() => {
            fieldsConfig[FIELDS.MANUFACTURER].loading = false;
            setFocusToDropdown(FIELDS.MANUFACTURER);
        });
};

const getEquipmentModels = (value, _, resolveNextDeps) => {
    const mfrId = value?.value;
    const vehicleId = fieldsConfig[FIELDS.VEHICLE].model.value;

    fieldsConfig[FIELDS.EQUIPMENT_MODEL].loading = true;

    Vue.CatalogApi.CatalogApiService.getEquipmentModels({ mfrId, vehicleId })
        .then((res) => {
            if (!res[FIELDS.EQUIPMENT_MODEL]?.length) {
                fieldsConfig[FIELDS.EQUIPMENT_MODEL].items = [EMPTY_ITEM];
                setModelAndEmitAction(FIELDS.EQUIPMENT_MODEL, resolveNextDeps);
            } else {
                fieldsConfig[FIELDS.EQUIPMENT_MODEL].items = res[FIELDS.EQUIPMENT_MODEL].map(
                    getOptionsFromResponse(VALUE_PROPERTY, LABEL_PROPERTY),
                );

                if (res[FIELDS.EQUIPMENT_MODEL].length === 1) {
                    setModelAndEmitAction(FIELDS.EQUIPMENT_MODEL, resolveNextDeps);
                } else if (resolveNextDeps) {
                    resolveNextDependentFields(FIELDS.EQUIPMENT_MODEL);
                }
            }
        })
        .catch(() => {
            fieldsConfig[FIELDS.EQUIPMENT_MODEL].items = [];
        })
        .finally(() => {
            fieldsConfig[FIELDS.EQUIPMENT_MODEL].loading = false;
            setFocusToDropdown(FIELDS.EQUIPMENT_MODEL);
        });
};

const getEquipmentYears = (value, _, resolveNextDeps) => {
    const modelId = value?.value;
    const mfrId = fieldsConfig[FIELDS.MANUFACTURER].model.value;
    const vehicleId = fieldsConfig[FIELDS.VEHICLE].model.value;

    fieldsConfig[FIELDS.EQUIPMENT_YEAR].loading = true;

    Vue.CatalogApi.CatalogApiService.getEquipmentYears({ mfrId, modelId, vehicleId })
        .then((res) => {
            if (!res[FIELDS.YEAR]?.length) {
                fieldsConfig[FIELDS.EQUIPMENT_YEAR].items = [
                    { value: "", label: Vue.i18n.get("All years") },
                ];
                setModelAndEmitAction(FIELDS.EQUIPMENT_YEAR, resolveNextDeps);
            } else {
                fieldsConfig[FIELDS.EQUIPMENT_YEAR].items = res[FIELDS.YEAR].map(
                    getOptionsFromResponse(VALUE_PROPERTY, LABEL_PROPERTY),
                );

                if (res[FIELDS.YEAR].length === 1) {
                    setModelAndEmitAction(FIELDS.EQUIPMENT_YEAR, resolveNextDeps);
                } else if (resolveNextDeps) {
                    resolveNextDependentFields(FIELDS.EQUIPMENT_YEAR);
                }
            }
        })
        .catch(() => {
            fieldsConfig[FIELDS.EQUIPMENT_YEAR].items = [];
        })
        .finally(() => {
            fieldsConfig[FIELDS.EQUIPMENT_YEAR].loading = false;
            setFocusToDropdown(FIELDS.EQUIPMENT_YEAR);
        });
};

const FIELDS_ACTIONS = {
    [FIELDS.YEAR]: getYears,
    [FIELDS.MAKE]: getMakes,
    [FIELDS.MODEL]: getModels,
    [FIELDS.EQUIPMENT_MODEL]: getEquipmentModels,
    [FIELDS.EQUIPMENT_YEAR]: getEquipmentYears,
    [FIELDS.MANUFACTURER]: getManufacturers,
    [FIELDS.VEHICLE]: getVehicleTypes,
};

const getApplicationTypes = (applicationTypesString, subBrand) => {
    initialField.loading = true;

    const applicationTypes = JSON.parse(applicationTypesString);

    Promise.resolve(
        applicationTypes.map(
            getApplicationTypesOptionsFromResponse(
                APPLICATION_TYPE_KEYS.value,
                APPLICATION_TYPE_KEYS.label,
            ),
        ),
    )
        .then((res) => (initialField.items = res))
        .finally(() => {
            initialField.loading = false;
        });
};

/* Configs */

const fieldsConfig = INITIAL_FIELDS_CONFIG;

const optionsConfig = {
    [LOOK_UP_IDS.YMM]: {
        onInput: resolveDependentFields(FIELDS.YEAR),
        fields: {
            [FIELDS.YEAR]: {
                onInput: resolveDependentFields(FIELDS.MAKE),
            },
            [FIELDS.MAKE]: {
                onInput: resolveDependentFields(FIELDS.MODEL),
            },
            [FIELDS.MODEL]: {
                onInput: enableSearchButton,
            },
        },
    },
    [LOOK_UP_IDS.EQUIPMENT]: {
        onInput: resolveDependentFields(FIELDS.VEHICLE),
        fields: {
            [FIELDS.VEHICLE]: {
                onInput: resolveDependentFields(FIELDS.MANUFACTURER),
            },
            [FIELDS.MANUFACTURER]: {
                onInput: resolveDependentFields(FIELDS.EQUIPMENT_MODEL),
            },
            [FIELDS.EQUIPMENT_MODEL]: {
                onInput: resolveDependentFields(FIELDS.EQUIPMENT_YEAR),
            },
            [FIELDS.EQUIPMENT_YEAR]: {
                onInput: enableSearchButton,
            },
        },
    },
};

const searchButton = {
    isDisabled: true,
};

const initialField = {
    ...INITIAL_FIELDS_CONFIG[FIELDS.APPLICATION_TYPE],
    name: FIELDS.APPLICATION_TYPE,
    getItems: getApplicationTypes,
    onInput: onApplicationTypeInput,
};

export default {
    fieldsConfig,
    optionsConfig,
    searchButton,
    initialField,
};
