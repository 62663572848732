var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ymm-search-results-page-container" },
    [
      !_vm.isInteractiveDiagram || !_vm.partsList.length
        ? _c("driv-refine-search", {
            attrs: {
              "search-labels": _vm.searchLabels,
              "handle-click": _vm.handleRefineSearchClick,
              "is-edit-mode": _vm.isSearchEdited
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isSearchEdited && _vm.vehiclegroupids
        ? _c(
            "div",
            { staticClass: "driv-ymm-search" },
            [
              _c("walker-common-ymm-search", {
                attrs: {
                  path: _vm.resultsPagePath,
                  types: _vm.catalogs,
                  vehiclegroupids: _vm.vehiclegroupids
                }
              })
            ],
            1
          )
        : _vm.isSearchEdited
        ? _c(
            "div",
            { staticClass: "driv-ymm-search" },
            [
              _c("driv-common-ymm-search", {
                attrs: { path: _vm.resultsPagePath, types: _vm.types }
              })
            ],
            1
          )
        : _vm.isLoading
        ? _c(
            "div",
            { staticClass: "three-quarters-loader" },
            [_c("fmmp-i18n", { attrs: { text: "loading..." } })],
            1
          )
        : !_vm.partsList.length
        ? _c(
            "h4",
            { staticClass: "driv-ymm-search__no-results" },
            [
              _c("fmmp-i18n", {
                attrs: {
                  text: "Nothing is found. Please adjust search criteria."
                }
              })
            ],
            1
          )
        : _c(
            "div",
            {
              class: [
                "ymm-search-results_parts-list-wrapper",
                {
                  walker: _vm.vehiclegroupids
                }
              ]
            },
            [
              _vm.isFiltersShown
                ? _c("driv-parts-result-filters", {
                    class: { "smaller-width": _vm.isInteractiveDiagram },
                    attrs: { onFilter: _vm.handleFilters, filters: _vm.filters }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isInteractiveDiagram && _vm.renderResultsFlag
                ? _c("driv-corp-diagram-results", {
                    attrs: {
                      detailsPath: _vm.detailsPath,
                      diagrams: _vm.interactiveDiagrams
                    }
                  })
                : _c(
                    "div",
                    {
                      class: [
                        "ymm-search-results_parts-list-container",
                        {
                          "full-width": !_vm.isFiltersShown
                        }
                      ]
                    },
                    [
                      _vm.renderResultsFlag
                        ? _c("driv-parts-list", {
                            attrs: {
                              products: _vm.filteredPartsList,
                              wtbPath: _vm.wtbPath,
                              anyType: _vm.anyType,
                              detailsPath: _vm.detailsPath
                            }
                          })
                        : _vm._e(),
                      _vm._v(
                        "\n            " + _vm._s(_vm.error) + "\n        "
                      )
                    ],
                    1
                  )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }