const handleCategoriesFilter = (part, categories) => {
    return Object.entries(categories).some(([category, subCategories]) => {
        if (category !== `${part?.category?.category_id}`) return false;
        if (!subCategories?.length) return true;
        return handleSubCategoriesFilter(part, subCategories);
    });
};

const handleSubCategoriesFilter = (part, subCategories) => {
    return subCategories?.includes(`${part?.category?.sub_category_id}`);
};

const handlePartTypesFilter = (part, partTypes) => {
    return Object.entries(partTypes).some(([partType, positions]) => {
        if (partType !== `${part?.category?.part_type_id}`) return false;
        if (!positions?.length) return true;
        return handlePositionsFilter(part, positions);
    });
};

const handlePositionsFilter = (part, positions) => {
    return positions?.includes(`${part?.position?.id}`);
};

export const filtersHandlers = {
    handleCategoriesFilter,
    handleSubCategoriesFilter,
    handlePartTypesFilter,
    handlePositionsFilter,
};
