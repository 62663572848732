<template>
    <div class="driv-refine-search">
        <div class="driv-refine-search__content">{{ searchValuesString }}</div>

        <button class="driv-refine-search__button" @click="handleClick">
            <fmmp-i18n v-if="isEditMode" text="Cancel" />
            <fmmp-i18n v-else text="Edit" />
        </button>
    </div>
</template>

<script>
const TS_BRAND = "ts";

export default Vue.component("driv-refine-search", {
    props: {
        searchLabels: {
            type: Object,
            default() {
                return {
                    typeLabel: "",
                    yearLabel: "",
                    makeLabel: "",
                    modelLabel: "",
                    vehicleLabel: "",
                    mfrLabel: "",
                    equipmentModelLabel: "",
                    equipmentYearLabel: "",
                };
            },
        },
        handleClick: Function,
        isEditMode: Boolean,
    },
    computed: {
        searchValuesString() {
            const searchValues = [];
            const {
                typeLabel,
                yearLabel,
                makeLabel,
                modelLabel,
                vehicleLabel,
                mfrLabel,
                equipmentModelLabel,
                equipmentYearLabel,
            } = this.searchLabels;

            if (typeLabel && window.brand !== TS_BRAND) {
                searchValues.push(typeLabel.toUpperCase());
            }

            if (yearLabel) {
                searchValues.push(yearLabel);
            }

            if (makeLabel) {
                searchValues.push(makeLabel);
            }

            if (modelLabel) {
                searchValues.push(modelLabel);
            }

            if (vehicleLabel) {
                searchValues.push(vehicleLabel);
            }

            if (mfrLabel) {
                searchValues.push(mfrLabel);
            }

            if (equipmentModelLabel) {
                searchValues.push(equipmentModelLabel);
            }

            if (equipmentYearLabel) {
                searchValues.push(equipmentYearLabel);
            }

            if (!searchValues.length) {
                return this.getTranslation(
                    "Something went wrong! Please check your search criteria."
                );
            }

            return searchValues.join(", ");
        },
    },
    methods: {
        getTranslation(value) {
            return Vue.i18n.get(value);
        },
    },
});
</script>