<template>
    <li class="driv-parts-list-item-container">
        <div class="driv-parts-list-item-image">
            <img :src="imageSrc" />
        </div>
        <div class="driv-parts-list-item-content">
            <div class="driv-parts-list-item-subheading">
                <fmmp-i18n text="Part No:" />
                <a
                    class="driv-parts-list-item-part-number"
                    @click="goToDetailsHandler(product)"
                    rel="noopener noreferrer"
                    target="_blank"
                    tabindex="0"
                >
                    {{ product.part_number }}
                </a>
                <button
                    class="interactive-diagrams-btn"
                    v-if="hasDiagram"
                    @click="openDiagramPage(product)"
                >
                    <fmmp-i18n text="Interactive Diagrams" />
                </button>
            </div>
            <div class="driv-parts-list-item-title">
                <a
                    @click="goToDetailsHandler(product)"
                    rel="noopener noreferrer"
                    target="_blank"
                    tabindex="0"
                >
                    {{ product.part_name }}
                </a>
            </div>
            <div class="driv-parts-list-item-details">
                <driv-tabs>
                    <driv-tab 
                        :name="getTranslation('Features')"
                        :tabsOnMobile="true">
                        <div class="details-item" v-if="product.product">
                            <span class="details-item-label">{{ getTranslation("Product") }}:</span>
                            <span class="details-item-value">{{ product.product }}</span>
                        </div>
                        <div class="details-item" v-if="product.position && product.position.value">
                            <span class="details-item-label">
                                {{ getTranslation("Position") }}:
                            </span>
                            <span class="details-item-value">{{ product.position.value }}</span>
                        </div>
                        <div class="details-item" v-if="product.qty">
                            <span class="details-item-label">
                                {{ getTranslation("Application Qty") }}:
                            </span>
                            <span class="details-item-value">{{ product.qty }}</span>
                        </div>
                        <div class="details-item with-icon" v-if="product.fits">
                            <span class="details-item-label">{{ getTranslation("Fits") }}:</span>
                            <span class="details-item-value">
                                <i class="fa fa-check-circle check-icon"></i>
                                {{ product.fits }}
                            </span>
                        </div>
                        <div class="details-remark" v-if="product.additional_fit_criteria">
                            {{
                                getTranslation(
                                    "Please refer to Additional Fit Criteria for a more precise match",
                                )
                            }}
                        </div>
                        <div class="details-item-info" v-if="product.additional_fit_criteria">
                            <span class="details-item-label">
                                <i class="fa fa-exclamation-triangle warning-icon"></i>
                                {{ getTranslation("Additional Fit Criteria") }}:
                            </span>
                            <span class="details-item-value">
                                {{ product.additional_fit_criteria }}
                            </span>
                        </div>
                    </driv-tab>
                    <driv-tab
                        :name="getTranslation('Description')"
                        :tabsOnMobile="true"
                        v-if="product.marketingDescription"
                    >
                        <p class="detail-description">
                            {{ product.marketingDescription }}
                        </p>
                    </driv-tab>
                    <driv-tab
                        :name="getTranslation('Technical')"
                        :tabsOnMobile="true"
                        v-if="product.dam_assets && product.dam_assets.productDocuments"
                    >
                        <div
                            v-for="doc in product.dam_assets.productDocuments"
                            :key="doc.url"
                            class="technical-tab-container"
                        >
                            <p class="technical-tab-title">{{ doc.title }}</p>
                            <a
                                :href="doc.url"
                                tabindex="0"
                                target="_blank"
                                :download="doc.fileName"
                            >
                                {{ doc.fileName }}
                            </a>
                        </div>
                    </driv-tab>
                </driv-tabs>
            </div>
            <div class="driv-parts-list-item-actions">
                <a
                    v-if="product.wtb_part_type"
                    @click="
                        () =>
                            handleWtbClick({
                                partType: product.wtb_part_type,
                                locType: 'install',
                                partNumber: product.part_number,
                            })
                    "
                    class="driv-parts-list-item-action-button cta-link"
                    tabindex="0"
                >
                    {{ getTranslation("Get it installed") }}
                </a>
                <a
                    v-if="product.wtb_part_type"
                    @click="
                        () =>
                            handleWtbClick({
                                partType: product.wtb_part_type,
                                locType: 'store',
                                partNumber: product.part_number,
                            })
                    "
                    class="driv-parts-list-item-action-button cta-link"
                    tabindex="0"
                >
                    {{ getTranslation("Buy in store") }}
                </a>
                <driv-dropdown-button
                    v-if="product.online_store_list && product.online_store_list.length"
                    buttonClasses="driv-parts-list-item-action-button button-main button-arrow"
                    :text="getTranslation('Buy now')"
                    tabindex="0"
                    :options="
                        mapPartOnlineStoreToOptions(product.online_store_list, product.part_number)
                    "
                ></driv-dropdown-button>
            </div>
        </div>
    </li>
</template>

<script>
import { WTB_EVENTS } from "../../../common/partFinderCorporate.constants";
import {
    createUrlToGoToSearchResults,
    mapPartOnlineStoreToOptions,
} from "../../../common/partFinderCorporate.helpers";
import { openDiagramPage } from "../../../driv-part-finder-part-results-page/clientlibs/src/helpers";

const setUpDropDowns = () => {
    const dropDownButtonContainers = document.querySelectorAll(".driv-dropdown-button");
    if (dropDownButtonContainers) {
        [...dropDownButtonContainers].forEach((container) => {
            let buyButtonFocus = false;
            const buyInStoreButton = container.previousSibling.previousSibling;
            const unorderedList = container.querySelector("ul");
            const lastOption = [...unorderedList.querySelectorAll("li")].pop();
            container.addEventListener("focusin", () => {
                buyButtonFocus = true;
                if (buyButtonFocus) {
                    unorderedList.style.display = "block";
                }
            });
            lastOption.addEventListener("focusout", () => {
                buyButtonFocus = false;
                if (!buyButtonFocus) {
                    unorderedList.style.display = "none";
                }
            });
            buyInStoreButton.addEventListener("focus", () => {
                buyButtonFocus = false;
                if (!buyButtonFocus) {
                    unorderedList.style.display = "none";
                }
            });
        });
    }
};
window.addEventListener("load", () => setUpDropDowns());

const DEFAULT_IMG_SRC = "/content/dam/placeholders/catalog-part-image.png";

export default Vue.component("driv-parts-list-item", {
    props: {
        product: Object,
        wtbPath: {
            type: String,
            default: "",
        },
        anyType: {
            type: String,
            default: "",
        },
        detailsPath: {
            type: String,
            default: "",
        },
    },
    computed: {
        partDetailUrl() {
            const PART_DETAIL_URL = `/en_US/find-my-part/part-detail.html?part_number=${this.product.part_number}`;
            const currentUrl = window.location.href;
            const partDetailUrl = currentUrl.replace(/\/en_US\/(.)*/, PART_DETAIL_URL);

            return partDetailUrl;
        },
        imageSrc() {
            return this.product.dam_assets?.productPrimaries[0]?.url || DEFAULT_IMG_SRC;
        },
        hasDiagram() {
            return !!this.product.digital_assets;
        },
    },
    methods: {
        getTranslation(value) {
            return Vue.i18n.get(value);
        },
        mapPartOnlineStoreToOptions,
        getWtbUrl(partType, locType) {
            const urlOrigin = window.location.origin;
            const anyType = this.anyType;
            if (anyType == "true" || window.brand === 'walker') {
                return `${urlOrigin}${this.wtbPath}.html#locType=${locType}&partType=any&subBrand=all`;
            }
            return `${urlOrigin}${this.wtbPath}.html#locType=${locType}&partType=${partType}&subBrand=all`;
        },
        handleWtbClick({ partType, locType, partNumber }) {
            const url = this.getWtbUrl(partType, locType);

            Vue.Global.Analytics.trackEvent("where-to-buy", WTB_EVENTS[locType], partNumber, null, {
                callback: function () {
                    window.location.assign(url);
                },
            });
        },
        handleGoToDetails(url) {
            Vue.Global.urlLocation.redirect(url);
        },
        // for brand sites with their own Details Page
        handleGoToBrandSiteDetails(partNumber, brandCode, path) {
            window.location.assign(
                createUrlToGoToSearchResults({
                    queryObj: { part_number: partNumber, brand_code: brandCode },
                    path,
                }),
            );
        },
        goToDetailsHandler(product) {
            Vue.Global.Analytics.trackEvent(
                "Part Search Results Link",
                "Part",
                product.part_number,
                null,
                {
                    callback: () => {
                        this.detailsPath
                            ? this.handleGoToBrandSiteDetails(
                                  product.part_number,
                                  product.brand,
                                  this.detailsPath,
                              )
                            : this.handleGoToDetails(product.part_detail_redirect_url);
                    },
                },
            );
        },
        openDiagramPage,
    },
});
</script>
