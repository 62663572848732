var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isModalVisible
    ? _c(
        "fmmp-modal",
        {
          attrs: { rootClass: "diagrams-results-page__modal" },
          on: { close: _vm.closeModal }
        },
        [
          _c("div", { staticClass: "modal-header" }),
          _vm._v(" "),
          _c("template", { slot: "title" }, [
            _c("div", { staticClass: "diagrams-results-page__modal-title" }, [
              _vm._v(_vm._s(_vm.title))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-table" }, [
            _c(
              "table",
              { staticClass: "diagrams-results-page__modal-content" },
              [
                _c(
                  "thead",
                  { staticClass: "diagrams-results-page__head" },
                  _vm._l(Object.values(_vm.columns), function(column) {
                    return _c(
                      "th",
                      {
                        key: column,
                        staticClass: "diagrams-results-page__head-cell"
                      },
                      [_c("fmmp-i18n", { attrs: { text: column } })],
                      1
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "tbody",
                  { staticClass: "diagrams-results-page__body" },
                  _vm._l(_vm.data, function(item, i) {
                    return _c("tr", { key: item.part_number + i }, [
                      _c(
                        "td",
                        [
                          _c("fmmp-i18n", {
                            staticClass: "diagrams-results-page__cell-heading",
                            attrs: { text: _vm.columns.partNumber }
                          }),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: {
                                rel: "noopener noreferrer",
                                target: "_blank"
                              },
                              on: {
                                click: function() {
                                  return _vm.handlePartNumberClick(
                                    item.part_number,
                                    item.brand
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.transformToMissedValue(item.part_number)
                                  ) +
                                  "\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("fmmp-i18n", {
                            staticClass: "diagrams-results-page__cell-heading",
                            attrs: { text: _vm.columns.partType }
                          }),
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.transformToMissedValue(item.part_type)
                              ) +
                              "\n                    "
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("fmmp-i18n", {
                            staticClass: "diagrams-results-page__cell-heading",
                            attrs: { text: _vm.columns.position }
                          }),
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.transformToMissedValue(
                                  item.position && item.position.value
                                )
                              ) +
                              "\n                    "
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("fmmp-i18n", {
                            staticClass: "diagrams-results-page__cell-heading",
                            attrs: { text: _vm.columns.drive }
                          }),
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.transformToMissedValue(
                                  item.drive && item.drive.value
                                )
                              ) +
                              "\n                    "
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("fmmp-i18n", {
                            staticClass: "diagrams-results-page__cell-heading",
                            attrs: { text: _vm.columns.qty }
                          }),
                          _vm._v(
                            "\n                        " +
                              _vm._s(item.qty) +
                              "\n                    "
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("fmmp-i18n", {
                            staticClass: "diagrams-results-page__cell-heading",
                            attrs: { text: _vm.columns.appNotes }
                          }),
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.transformToMissedValue(
                                  item.additional_fit_criteria
                                )
                              ) +
                              "\n                    "
                          )
                        ],
                        1
                      )
                    ])
                  }),
                  0
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer" })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }