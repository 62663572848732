<template>
    <div class="ymm-search-results-page-container">
        <driv-refine-search
            v-if="!isInteractiveDiagram || !partsList.length"
            :search-labels="searchLabels"
            :handle-click="handleRefineSearchClick"
            :is-edit-mode="isSearchEdited"
        ></driv-refine-search>
        <div v-if="isSearchEdited && vehiclegroupids" class="driv-ymm-search">
            <walker-common-ymm-search :path="resultsPagePath" :types="catalogs" :vehiclegroupids="vehiclegroupids"></walker-common-ymm-search>
        </div>
        <div v-else-if="isSearchEdited" class="driv-ymm-search">
            <driv-common-ymm-search :path="resultsPagePath" :types="types"></driv-common-ymm-search>
        </div>
        <div v-else-if="isLoading" class="three-quarters-loader">
            <fmmp-i18n text="loading..." />
        </div>
        <h4 v-else-if="!partsList.length" class="driv-ymm-search__no-results">
            <fmmp-i18n text="Nothing is found. Please adjust search criteria." />
        </h4>
        <div v-else 
            :class="[
                'ymm-search-results_parts-list-wrapper',
                {
                    'walker': vehiclegroupids,
                },
            ]">
            <driv-parts-result-filters
                v-if="isFiltersShown"
                :onFilter="handleFilters"
                :filters="filters"
                :class="{ 'smaller-width': isInteractiveDiagram }"
            ></driv-parts-result-filters>
            <driv-corp-diagram-results
                v-if="isInteractiveDiagram && renderResultsFlag"
                :detailsPath="detailsPath"
                :diagrams="interactiveDiagrams"
            ></driv-corp-diagram-results>
            <div
                v-else
                :class="[
                    'ymm-search-results_parts-list-container',
                    {
                        'full-width': !isFiltersShown,
                    },
                ]"
            >
                <driv-parts-list
                    :products="filteredPartsList"
                    v-if="renderResultsFlag"
                    :wtbPath="wtbPath"
                    :anyType="anyType"
                    :detailsPath="detailsPath"
                ></driv-parts-list>
                {{ error }}
            </div>
        </div>
    </div>
</template>

<script>
import InteractiveDiagram from "../../../driv-part-finder-part-results-page/clientlibs/src/diagram-results/InteractiveDiagram.vue";
import { filtersHandlers } from "../../../common/ymm-search-results/helpers";
import {
    getFiltersForRequest,
    getQsParams,
    replaceEmptyWithNull,
} from "../../../driv-part-finder-part-results-page/clientlibs/src/helpers";
import {
    FILTERS_QS_OPTIONS,
} from "../../../driv-part-finder-part-results-page/clientlibs/src/constants";

const APPLICATION_LIST_PROPERTY = "application_list";
const APPLICATIONS_PROPERTY = "applications";
const INTERACTIVE_DIAGRAMS_PROPERTY = "interactive_assets";
const MAP_FILTERS_TO_HANDLERS = {
    categories: (part, filters) => filtersHandlers.handleCategoriesFilter(part, filters),
    sub_categories: (part, filters) => filtersHandlers.handleSubCategoriesFilter(part, filters),
    part_type: (part, filters) => filtersHandlers.handlePartTypesFilter(part, filters),
    position: (part, filters) => filtersHandlers.handlePositionsFilter(part, filters),
};

export default Vue.component("driv-ymm-search-results-page", {
    components: {
        InteractiveDiagram,
    },
    data() {
        return {
            isSearchEdited: false,
            renderResultsFlag: true,
            searchLabels: {
                typeLabel: "",
                yearLabel: "",
                makeLabel: "",
                modelLabel: "",
                vehicleLabel: "",
                mfrLabel: "",
                equipmentModelLabel: "",
                equipmentYearLabel: "",
            },
            partsList: [],
            interactiveDiagrams: [],
            selectedFilters: {
                categories: {},
                configs: {},
            },
            error: "",
            filters: {
                categories: [],
                configs: {},
                partTypes: [],
            },
            isLoading: true,
            partsApiCancelSrc: null,
        };
    },
    props: {
        types: {
            type: String,
            default: "",
        },
        catalogs: {
            type: String,
            default: "",
        },
        vehiclegroupids: {
            type: String,
            default: "",
        },
        wtbPath: {
            type: String,
            default: "",
        },
        anyType: {
            type: String,
            default: "",
        },
        detailsPath: {
            type: String,
            default: "",
        },
    },
    computed: {
        isFiltersShown() {
            const { categories, configs, partTypes } = this.filters;

            if (categories.length || partTypes.length) return true;

            const configsFilters = Object.values(configs);

            return configsFilters.some((filter) => filter.length);
        },
        resultsPagePath: () => {
            return window.location.pathname.replace(".html", "");
        },
        filteredPartsList() {
            if (
                !Object.keys(this.selectedFilters?.configs).length &&
                !Object.keys(this.selectedFilters?.categories).length
            ) {
                return this.partsList;
            }

            return this.partsList?.filter((part) => {
                let isInConfigs = Object.entries(this.selectedFilters.configs).every(
                    ([field, filters]) => {
                        if (!part[field]?.id) return true;

                        return filters.includes(part[field].id);
                    },
                );

                let isInCategories = Object.entries(this.selectedFilters.categories).every(
                    ([field, filters]) => {
                        return MAP_FILTERS_TO_HANDLERS[field](part, filters);
                    },
                );

                return isInConfigs && isInCategories;
            });
        },
        isInteractiveDiagram() {
            const { isInteractiveDiagram } = getQsParams();
            return isInteractiveDiagram;
        },
    },
    methods: {
        getPartListData(params) {
            const {
                type,
                make,
                model,
                year,
                subBrand,
                vehicle,
                mfr,
                equipmentModel,
                equipmentYear,
                isInteractiveDiagram,
                brandInfo,
                catalogId,
                emissionCertificateId,
            } = params;
            const filters = getFiltersForRequest(this.filters);
            const commonRequestParams = {
                typeId: type?.value,
                makeId: make?.value,
                modelId: model?.value,
                yearId: year?.value,
                vehicleId: vehicle?.value,
                mfrId: mfr?.value,
                equipmentModelId: equipmentModel?.value,
                equipmentYearId: equipmentYear?.value,
                catalogId: catalogId?.value,
            };
            if (this.isInteractiveDiagram) {
                this.renderResultsFlag = false;
                this.partsApiCancelSrc?.cancel();
                this.partsApiCancelSrc = Vue.CatalogApi.CatalogHttpService.getCancelTokenSource();
                Vue.CatalogApi.CatalogApiService.getPartsList({
                    ...commonRequestParams,
                    filters,
                    subBrand,
                    isInteractiveDiagram,
                    brandCode: brandInfo?.value,
                    cancelToken: this.partsApiCancelSrc.token,
                })
                .then((response) => {
                    this.setResultsDataFromResponse(response);
                })
                .catch(() => {
                    this.error = "No data is found";
                })
                .finally(() => {
                    this.isLoading = false;
                    this.renderResultsFlag = true;
                });
            } else {
                Vue.CatalogApi.CatalogApiService.getPartsList({
                    ...commonRequestParams,
                    subBrand,
                    emissionCertificateId: emissionCertificateId?.value,
                })
                .then((response) => {
                    this.setResultsDataFromResponse(response);
                })
                .catch(() => {
                    this.error = "No data is found";
                })
                .finally(() => {
                    this.isLoading = false;
                });
            }
        },
        handleRefineSearchClick() {
            this.isSearchEdited = !this.isSearchEdited;
        },
        handleFilters(selectedFilters) {
            this.selectedFilters = selectedFilters;
            if (this.isInteractiveDiagram) {
                const { configs, categories } = selectedFilters;
                // this.selectedFilters = selectedFilters;

                const filters = {
                    ...(Object.values(configs).length ? { configs } : {}),
                    ...(Object.values(categories).length ? { categories } : {}),
                };

                const stringifiedParams = Qs.stringify(
                    { filters: replaceEmptyWithNull(filters) },
                    {
                        ...FILTERS_QS_OPTIONS,
                        encoder: (val) => {
                            if (typeof val === "number") return `${val}`;

                            return val;
                        },
                    },
                );
                const { top } = document.body.getBoundingClientRect();

                window.location.hash = stringifiedParams;

                // when assigning '' to hash page scrolls up,
                // to prevent this save and restore current scroll position
                if (!stringifiedParams) window.scrollTo(0, -top);

                const params = Qs.parse(window.location.search.slice(1));
                this.getPartListData(params);
            }
        },
        setResultsDataFromResponse(response) {
            this.error = "";
            this.partsList = response[APPLICATION_LIST_PROPERTY][APPLICATIONS_PROPERTY];
            this.filters.categories = response.category_tree.categories;
            this.filters.configs = response.configs;
            this.filters.partTypes = response.category_tree.part_type_positions;
            this.interactiveDiagrams = response[INTERACTIVE_DIAGRAMS_PROPERTY] || [];
        },
    },
    created() {
        const params = Qs.parse(window.location.search.slice(1));

        const {
            type,
            make,
            model,
            year,
            subBrand,
            vehicle,
            mfr,
            equipmentModel,
            equipmentYear,
            isInteractiveDiagram,
            brandInfo,
            catalogId,
        } = params;

        // double decoding here is to avoid situation when in encoded url we have light%2520vehicle,
        // this decoded by Qs to light%20vehicle, so after additional decoding we will get correct UI string
        this.searchLabels = {
            typeLabel: decodeURIComponent(type?.label || ""),
            yearLabel: decodeURIComponent(year?.label || ""),
            makeLabel: decodeURIComponent(make?.label || ""),
            modelLabel: decodeURIComponent(model?.label || ""),
            vehicleLabel: decodeURIComponent(vehicle?.label || ""),
            mfrLabel: decodeURIComponent(mfr?.label || ""),
            equipmentModelLabel: decodeURIComponent(equipmentModel?.label || ""),
            equipmentYearLabel: decodeURIComponent(equipmentYear?.label || ""),
        };
        this.getPartListData(params);
    },
});
</script>
