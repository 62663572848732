import { getInitialFieldConfig } from "./helpers";

export const FIELDS = {
    YEAR: "years",
    MAKE: "makes",
    MODEL: "models",
    APPLICATION_TYPE: "application_type",
    VEHICLE: "vehicle_types",
    MANUFACTURER: "mfrs",
    EQUIPMENT_MODEL: "equipment_models",
    EQUIPMENT_YEAR: "equipment_years",
};

export const INITIAL_FIELDS_CONFIG = {
    [FIELDS.APPLICATION_TYPE]: getInitialFieldConfig("Application type"),
    [FIELDS.YEAR]: getInitialFieldConfig("Year"),
    [FIELDS.MAKE]: getInitialFieldConfig("Make"),
    [FIELDS.MODEL]: getInitialFieldConfig("Model"),
    [FIELDS.EQUIPMENT_MODEL]: getInitialFieldConfig("Model"),
    [FIELDS.EQUIPMENT_YEAR]: getInitialFieldConfig("Year"),
    [FIELDS.MANUFACTURER]: getInitialFieldConfig("Manufacturer"),
    [FIELDS.VEHICLE]: getInitialFieldConfig("Vehicle type"),
};

export const EMPTY_ITEM = {
    value: "",
    label: "---",
};
