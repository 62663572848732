var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoading
    ? _c("div", { staticClass: "three-quarters-loader" })
    : _c(
        "div",
        { staticClass: "driv-corp-diagram-results" },
        [
          _c(
            "div",
            { key: _vm.componentKey, staticClass: "diagram-results_header" },
            [
              _c("div", { staticClass: "back-to-product-link" }, [
                _c("a", { on: { click: _vm.onBackToProductListClick } }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.getTranslation("Back To Search Results")) +
                      "\n            "
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "product-info" },
                [
                  _c("div", { staticClass: "product-logo" }, [
                    _vm.imageExists
                      ? _c("img", {
                          attrs: {
                            src: _vm.getBrandImgUrl(_vm.brandName),
                            alt: _vm.brandName
                          },
                          on: { error: _vm.onErrorLoad }
                        })
                      : _c("span", { staticClass: "brand" }, [
                          _vm._v(_vm._s(_vm.brandName))
                        ])
                  ]),
                  _vm._v(" "),
                  _c("fmmp-i18n", { attrs: { text: "Interactive diagrams" } })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "all-diagrams-text" },
                [
                  _c("fmmp-i18n", { attrs: { text: "All diagrams for the" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.modelInfo))])
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _vm.isError
            ? _c("driv-empty-part-results-page")
            : [
                _c("driv-diagram-results-carousel", {
                  attrs: {
                    toggleModal: _vm.handleHotspotClick,
                    carouselImages: _vm.svgDiagrams,
                    handleDiagramSelect: _vm.handleDiagramSelect
                  }
                }),
                _vm._v(" "),
                _c("driv-diagram-info-modal", {
                  attrs: {
                    isModalVisible: _vm.isModalVisible,
                    data: _vm.selectedHotspotData,
                    title: _vm.selectedDiagramTitle,
                    detailsPath: _vm.detailsPath
                  },
                  on: { toggleDiagramInfoModal: _vm.handleToggleModal }
                })
              ]
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }