var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "part-number_wrapper" }, [
    _c("div", { staticClass: "part-number-input-wrapper" }, [
      _c("div", { staticClass: "input-wrapper" }, [
        _c(
          "form",
          {
            staticClass: "input-wrapper-form",
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.handleSearch.apply(null, arguments)
              }
            }
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.partNumberValue,
                  expression: "partNumberValue",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "part-number-input",
              attrs: {
                type: "text",
                "aria-label": _vm.getTranslation("PART NUMBER"),
                placeholder: _vm.getTranslation("PART NUMBER")
              },
              domProps: { value: _vm.partNumberValue },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.partNumberValue = $event.target.value.trim()
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            })
          ]
        ),
        _vm._v(" "),
        _c("button", {
          staticClass: "button-arrow-part-number",
          attrs: {
            "aria-label": "Arrow-button",
            "data-component": "driv-part-number-search-button"
          },
          on: { click: _vm.handleSearch }
        })
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button-main button-down",
          attrs: { "data-component": "driv-part-number-search-button" },
          on: { click: _vm.handleSearch }
        },
        [_c("fmmp-i18n", { attrs: { text: "search" } })],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }