var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "driv-part-finder-main-heading" }, [
    _c("div", { staticClass: "driv-part-finder-main-default-text" }, [
      _c(
        "h2",
        { staticClass: "driv-part-finder-main-default-title" },
        [_c("fmmp-i18n", { attrs: { text: "What can we help you find?" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "driv-part-finder-main-default-description" },
        [
          _c("fmmp-i18n", {
            attrs: {
              text:
                "Search for " +
                _vm.brand +
                "® products with our easy part finder or by your\n      interchange number. Also find " +
                _vm.brand +
                "® products near you with our\n      store locator."
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }