/* 
    This codesnippet is taken from a Walker diagram.
    Replaced document -> selectedDiagram, to apply interactivity only for selected svg.
*/
export const addInteractivityForWalkerDiagram = (selectedDiagram) => {
    var e = function () {
            var e = "#label-" + this.getAttribute("class");
            selectedDiagram.querySelectorAll(e).forEach(function (e) {
                e.classList.add("selected");
            });
        },
        t = function () {
            var e = "#label-" + this.getAttribute("class");
            selectedDiagram.querySelectorAll(e).forEach(function (e) {
                e.classList.remove("selected");
            });
        },
        r = selectedDiagram.querySelectorAll("#apps tr");
    for (var o of r) o.addEventListener("mouseover", e), o.addEventListener("mouseout", t);
    var s = function () {
            var e = "." + this.getAttribute("id").substring(6);
            selectedDiagram.querySelectorAll(e).forEach(function (e) {
                e.classList.add("selected");
            });
        },
        n = function () {
            var e = "." + this.getAttribute("id").substring(6);
            selectedDiagram.querySelectorAll(e).forEach(function (e) {
                e.classList.remove("selected");
            });
        },
        c = selectedDiagram.querySelectorAll("#labels text");
    for (var l of c) l.addEventListener("mouseover", s), l.addEventListener("mouseout", n);
};
