var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "driv-parts-list-container" }, [
    _c(
      "h4",
      { key: this.products.length, staticClass: "driv-parts-list-quantity" },
      [
        _vm._v("\n        " + _vm._s(this.products.length) + "\n        "),
        _c("fmmp-i18n", { attrs: { text: "Part Results" } })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "driv-parts-list" },
      _vm._l(_vm.products, function(product, i) {
        return _c("driv-parts-list-item", {
          key: i + product.part_number,
          attrs: {
            wtbPath: _vm.wtbPath,
            anyType: _vm.anyType,
            product: product,
            detailsPath: _vm.detailsPath
          }
        })
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }