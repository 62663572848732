<template>
    <div class="driv-parts-list-container">
        <h4 class="driv-parts-list-quantity" :key="this.products.length">
            {{ this.products.length }}
            <fmmp-i18n text="Part Results" />
        </h4>
        <ul class="driv-parts-list">
            <driv-parts-list-item
                v-for="(product, i) in products"
                :key="i + product.part_number"
                :wtbPath="wtbPath"
                :anyType="anyType"
                :product="product"
                :detailsPath="detailsPath"
            ></driv-parts-list-item>
        </ul>
    </div>
</template>

<script>
export default Vue.component("driv-parts-list", {
    props: {
        products: {
            type: Array,
            default() {
                return [];
            },
        },
        wtbPath: {
            type: String,
            default: "",
        },
        anyType: {
            type: String,
            default: "",
        },
        detailsPath: {
            type: String,
            default: "",
        },
    },
});
</script>
