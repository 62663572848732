import { FILTERS_QS_OPTIONS } from "../driv-part-finder-part-results-page/clientlibs/src/constants";
import { getQsParams } from "../driv-part-finder-part-results-page/clientlibs/src/helpers";

const OVERLAY_CLASSNAME = "overlay";
const GPI_API_PATH = ".gpi.";

export const isGPI = () => window.brand === "gpi";

export const createUrlToGoToSearchResults = ({ queryObj, path, hashString }) => {
    let updatedPath = path;
    const urlOrigin = window.location.origin;
    const { signed_request } = getQsParams();
    const signedRequest = document.getElementById("signed_request")?.value || signed_request;

    const newQueryObject = {
        ...queryObj,
        ...(signedRequest
            ? {
                  signed_request: signedRequest,
              }
            : {}),
    };

    const queryString = Qs.stringify(newQueryObject, {
        encodeValuesOnly: true,
    });

    if (window.brand === "gpi" && updatedPath.includes(GPI_API_PATH)) {
        updatedPath = updatedPath.replace(GPI_API_PATH, "");
    }

    const urlPath = updatedPath.startsWith("/") ? updatedPath : `/${updatedPath}`;

    return `${urlOrigin}${urlPath}.html?${queryString}${
        hashString ? "#" + Qs.stringify(hashString) : ""
    }`;
};

export const mapPartOnlineStoreToOptions = (onlineStoreList, partNumber) => {
    return onlineStoreList.map((store) => {
        const lowerCaseName = store.storeName.toLowerCase();
        const url = store.buyOnlineUrl;

        return {
            text: store.storeName,
            onClick() {
                Vue.Global.Analytics.trackEvent(
                    "where-to-buy",
                    `click-buy-now-${lowerCaseName}`,
                    partNumber,
                    null,
                    {
                        callback: function () {
                            Vue.Global.urlLocation.redirect(url);
                        },
                    },
                );
            },
        };
    });
};

export const getTranslation = (value, vars, desc) => Vue.i18n.get(value, vars, desc);

export const transformToMissedValue = (field, returnValue = "—") => {
    return field && field !== "-" && field !== "N/A" ? field : returnValue;
};

export const mapVinOrLicensePlateResponse = (response) => {
    return Object.entries(response).reduce(
        (result, [key, val]) => ({
            ...result,
            [key]: { label: val.value, value: val.id },
        }),
        {},
    );
};

export const createQueryStringForFilters = (response) => {
    const configs = Object.entries(response).reduce((result, [key, val]) => {
        if (val) {
            return { ...result, [key]: [val.id] };
        }
        return result;
    }, {});

    return Qs.stringify(
        {
            filters: { configs },
        },
        {
            ...FILTERS_QS_OPTIONS,
            encoder: (val) => {
                if (typeof val === "number") return `${val}`;
                return val;
            },
        },
    );
};

export const mapLicensePlateStatesArray = (response) => {
    return response.states_provinces.map((state) => ({
        id: state.state_or_province_code,
        label: state.state_or_province_name,
    }));
};

export const scrollTabs = (dir) => {
    const elementContainers = document.querySelectorAll(".driv-tabs__header_container");
    elementContainers.forEach((container) => {
        const ulContainer = container.querySelector(".driv-tabs__header");
        dir === "left" ? (ulContainer.scrollLeft -= 150) : (ulContainer.scrollLeft += 150);
    });
};

export const getBrandImgUrl = (brand) => {
    const damPath = "/content/dam/marketing/North-America/fmmp/brand-logos/";
    let brandFromObject;

    // for PartsGridView
    if (typeof brand === "object") {
        brandFromObject = brand.part_type_list[0]?.parts_list[0].wtb_brand_name;
        brandFromObject =  brand.part_type_list[0]?.parts_list[0].pdm_brand_name == 'Wagner Sensors' ? 'wagner-sensors' : brandFromObject;
    }

    const brandName = (brandFromObject || brand)
        .toLowerCase()
        .replace("-", "")
        .replace("/", "")
        .replace(/\s+/g, "");

    return `${damPath}logo-${brandName}.png`;
};

export const getCorrectUrlForLinks = (url = "") => {
    const hasDotInUrl = url?.indexOf(".") !== -1;

    if (!hasDotInUrl) {
        const hasSlashInTheEnd = url?.charAt(url?.length - 1) === "/";
        return `${hasSlashInTheEnd ? url?.substring(0, url?.length - 1) : url}.html`;
    }

    return url;
};

// overlay for modals

export const createOverlay = (closeModalFunc) => {
    const overlay = document.createElement("div");
    overlay.className = OVERLAY_CLASSNAME;
    overlay.addEventListener("click", () => closeModalFunc());
    document.body.appendChild(overlay);
};

export const removeOverlay = () => {
    const overlay = document.getElementsByClassName(OVERLAY_CLASSNAME);
    document.body.removeChild(overlay[0]);
};
