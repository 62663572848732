var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "part-number-page-content_table-header" }, [
        _c("div", { staticClass: "brand-name table-header" }, [
          _vm._v(_vm._s(_vm.brandNameLabel))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "part-type table-header" }, [
          _vm._v(_vm._s(_vm.partTypeLabel))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "part-number table-header" }, [
          _vm._v(_vm._s(_vm.partNumberLabel))
        ]),
        _vm._v(" "),
        _vm.isInterchange
          ? _c("div", { staticClass: "own-part-number table-header" }, [
              _vm._v(
                "\n            " + _vm._s(_vm.ownPartNumberLabel) + "\n        "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "part-ctas table-header" })
      ]),
      _vm._v(" "),
      _vm._l(_vm.products, function(part) {
        return _c("div", { key: part.part_number }, [
          _c("div", { staticClass: "part-number-page-content_table" }, [
            _c("div", { staticClass: "brand-name" }, [
              _c("span", { staticClass: "only-mobile-label" }, [
                _vm._v(" " + _vm._s(_vm.brandNameLabel) + ": ")
              ]),
              _vm._v(
                "\n                " +
                  _vm._s(_vm.getBrandNameTranslatedForTS(part.brand_name)) +
                  "\n            "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "part-type" }, [
              _c("span", { staticClass: "only-mobile-label" }, [
                _vm._v(_vm._s(_vm.partTypeLabel) + ": ")
              ]),
              _vm._v(
                "\n                " + _vm._s(part.part_name) + "\n            "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "part-number" }, [
              _c("span", { staticClass: "only-mobile-label" }, [
                _vm._v(" " + _vm._s(_vm.partNumberLabel) + ": ")
              ]),
              _vm._v(" "),
              !_vm.isInterchange
                ? _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.goToDetailsHandler(part)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(part.part_number) +
                          "\n                "
                      )
                    ]
                  )
                : _c("span", [_vm._v(_vm._s(part.part_number))])
            ]),
            _vm._v(" "),
            _vm.isInterchange
              ? _c("div", { staticClass: "own_part_number" }, [
                  _c("span", { staticClass: "only-mobile-label" }, [
                    _vm._v(" " + _vm._s(_vm.ownPartNumberLabel) + ": ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.goToDetailsHandler(part)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(part.own_part_number) +
                          "\n                "
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "part-ctas" },
              [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: part.wtb_part_type,
                        expression: "part.wtb_part_type"
                      }
                    ],
                    staticClass: "part-number-page-content-part-cta cta-link",
                    on: {
                      click: function() {
                        return _vm.handleWtbClick({
                          partType: part.wtb_part_type,
                          locType: "install",
                          partNumber: part.part_number
                        })
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.getTranslation("Get it installed")) +
                        "\n                "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: part.wtb_part_type,
                        expression: "part.wtb_part_type"
                      }
                    ],
                    staticClass: "part-number-page-content-part-cta cta-link",
                    on: {
                      click: function() {
                        return _vm.handleWtbClick({
                          partType: part.wtb_part_type,
                          locType: "store",
                          partNumber: part.part_number
                        })
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.getTranslation("Buy in store")) +
                        "\n                "
                    )
                  ]
                ),
                _vm._v(" "),
                part.online_store_list && part.online_store_list.length
                  ? _c("driv-dropdown-button", {
                      attrs: {
                        buttonClasses:
                          "part-number-page-content-part-cta button-main button-arrow",
                        text: _vm.getTranslation("Buy now"),
                        options: _vm.mapPartOnlineStoreToOptions(
                          part.online_store_list,
                          part.part_number
                        )
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }