const categoriesSubLevels = ["categories", "sub_categories"];
const partTypesSubLevels = ["part_type", "position"];
const brandsSubLevels = ["brands", "sub_brands"];
export const ENABLED_PROPERTY_NAME = "enabled";
export const PART_TYPES_LEVELS = {
    PART_TYPES: "part_type",
    POSITIONS: "position",
};

export const FILTERS_LEVELS = {
    CATEGORIES: "categories",
    SUB_CATEGORIES: "sub_categories",
    PART_TYPES: "part_types",
    POSITIONS: "positions",
    BRANDS: "brands",
    SUB_BRANDS: "sub_brands",
};

const FILTERS_HIGH_LEVEL = [
    FILTERS_LEVELS.CATEGORIES,
    PART_TYPES_LEVELS.PART_TYPES,
    FILTERS_LEVELS.BRANDS,
];

export const PART_TYPES_IDS_PROPERTY = "partTypesIds";
export const CATEGORIES_IDS_PROPERTY = "categoriesIds";

const BRAND_PROPERTIES = { ID: "code", VALUE: "name" };
const DEFAULT_PROPERTIES = {
    ID: "id",
    VALUE: "value",
};

let parentGroupId;

export const mapToOptions = ({ initialArr, parentId = "", subLevels = [] }) => {
    return (
        initialArr &&
        initialArr.map((option) => {
            const childIndex = subLevels.findIndex((el) => !!option?.[el]);
            const levelName = subLevels.find((el) => !!option?.[el]);
            const currentLevel =
                childIndex !== -1 ? subLevels[childIndex - 1] : subLevels[subLevels.length - 1];
            const composedId = `${parentId}${option.id}`;

            if (currentLevel === subLevels[0]) {
                parentGroupId = option.id;
            }

            return {
                ...option,
                ...(levelName
                    ? {
                          options: mapToOptions({
                              initialArr: option[levelName],
                              parentId: `${composedId}_`,
                              subLevels,
                          }),
                      }
                    : {}),
                ...(childIndex !== -1 ? { childNames: subLevels.slice(childIndex) } : {}),
                levelName: currentLevel,
                parentGroupId,
                composedId,
            };
        })
    );
};

const getIdFromComposed = (value) => {
    return value.map((item) => {
        const idsArr = item.split("_");

        return idsArr[idsArr.length - 1];
    });
};

export const getUniqueValuesFromArrays = (firstArr, secondArr) => [
    ...new Set((firstArr || []).concat(secondArr)),
];

export const normalizeCheckedValues = (checkedValues, ignoreValidGroups = false) => {
    return Object.entries(checkedValues).reduce(
        (acc, [field, values]) => {
            if (field === ENABLED_PROPERTY_NAME && !ignoreValidGroups) {
                const normalizedAvailableConfigValues = normalizeCheckedValues(
                    Object.entries(values).reduce(
                        (accum, [key, items]) => ({
                            ...accum,
                            [key.replace("_id", "")]: items,
                        }),
                        {},
                    ),
                );

                return {
                    ...acc,
                    configs: {
                        ...acc.configs,
                        ...normalizedAvailableConfigValues.configs,
                    },
                };
            }

            if (Array.isArray(values) && values?.length) {
                return {
                    ...acc,
                    configs: {
                        ...acc.configs,
                        [field]: getUniqueValuesFromArrays(acc.configs[field], values),
                    },
                };
            }

            Object.entries(values).forEach(([key, value]) => {
                if (value?.length) {
                    if (FILTERS_HIGH_LEVEL.includes(key)) {
                        acc.categories[key] = value.reduce((result, item) => {
                            return { ...result, [item]: result[item] || [] };
                        }, acc.categories[key] || {});
                    } else {
                        switch (key) {
                            case FILTERS_LEVELS.SUB_CATEGORIES: {
                                acc.categories[FILTERS_LEVELS.CATEGORIES] = groupByComposedId(
                                    value,
                                    acc.categories[FILTERS_LEVELS.CATEGORIES],
                                );
                                break;
                            }
                            case PART_TYPES_LEVELS.POSITIONS: {
                                acc.categories[PART_TYPES_LEVELS.PART_TYPES] = groupByComposedId(
                                    value,
                                    acc.categories[PART_TYPES_LEVELS.PART_TYPES],
                                );
                                break;
                            }
                            case FILTERS_LEVELS.SUB_BRANDS: {
                                acc.categories[FILTERS_LEVELS.BRANDS] = groupByComposedId(
                                    value,
                                    acc.categories[FILTERS_LEVELS.BRANDS],
                                );
                                break;
                            }
                        }
                    }
                }
            });
            return acc;
        },
        { configs: {}, categories: {} },
    );
};

export const mapCategoriesToOptions = (categories) => {
    // enriched subCategories and categories wit part types, that are valid for them
    const categoriesWithPartTypes = categories.map((category) => {
        const subCategories = category[FILTERS_LEVELS.SUB_CATEGORIES];
        const categoryPartTypes = [];

        if (subCategories.length) {
            subCategories.forEach((subCategory) => {
                subCategory[PART_TYPES_IDS_PROPERTY] = subCategory[FILTERS_LEVELS.PART_TYPES].map(
                    (partType) => partType.id,
                );
                categoryPartTypes.push(...subCategory[PART_TYPES_IDS_PROPERTY]);
            });
        }
        category[PART_TYPES_IDS_PROPERTY] = categoryPartTypes;
        return category;
    });

    return mapToOptions({
        initialArr: categoriesWithPartTypes,
        subLevels: categoriesSubLevels,
    });
};

export const mapPartTypesToOptions = (typesGroups) => {
    // converted BE response to tree structure to display nested checkboxes
    const treeStructure = typesGroups.map((typeGroup) => {
        return {
            ...typeGroup[PART_TYPES_LEVELS.PART_TYPES],
            disabled: false,
            [PART_TYPES_LEVELS.POSITIONS]: typeGroup[PART_TYPES_LEVELS.POSITIONS],
        };
    });

    return mapToOptions({
        initialArr: treeStructure,
        subLevels: partTypesSubLevels,
    });
};

const remapBrandProperties = (brand) => ({
    [DEFAULT_PROPERTIES.ID]: brand[BRAND_PROPERTIES.ID],
    [DEFAULT_PROPERTIES.VALUE]: brand[BRAND_PROPERTIES.VALUE],
});

export const mapBrandsToOptions = (brands) => {
    if (!brands) return;

    const brandsWithCorrectProperties = brands.map((brand) => ({
        ...remapBrandProperties(brand),
        [FILTERS_LEVELS.SUB_BRANDS]: brand[FILTERS_LEVELS.SUB_BRANDS].map(remapBrandProperties),
        [CATEGORIES_IDS_PROPERTY]: (brand.categories || []).map((item) => item.id),
    }));

    return mapToOptions({
        initialArr: brandsWithCorrectProperties,
        subLevels: brandsSubLevels,
    });
};

export const getPropertyFoundById = ({ array, id, property }) => {
    const elem = array?.find((item) => `${item.id}` === `${id}`);
    if (elem) return elem[property];
    return;
};

const groupByComposedId = (arrayToGroup, initialObject = {}) => {
    return arrayToGroup.reduce((acc, item) => {
        const [parentId, id] = item.split("_");

        return { ...acc, [parentId]: (acc[parentId] || []).concat(id) };
    }, initialObject);
};

const getValuesAsArray = (values) => {
    if (typeof values === "string") return [values];

    return values || [];
};

const getStateFromCategories = ({ categories, upperLevelKey, nestedLevelKey }) => {
    if (!categories) return {};
    const upperLevelValue = categories[upperLevelKey];

    if (!!upperLevelValue) {
        return Object.entries(upperLevelValue).reduce((acc, [key, values]) => {
            return {
                ...acc,
                [`${key}`]: {
                    [upperLevelKey]: [key],
                    [nestedLevelKey]: getValuesAsArray(values).reduce((acc, value) => {
                        if (value) return [...acc, `${key}_${value}`];
                        return acc;
                    }, []),
                },
            };
        }, {});
    }

    return {};
};

const getStateFromConfigs = (configs) => {
    if (!configs) return;

    return Object.entries(configs).reduce(
        (acc, [key, value]) => ({
            ...acc,
            [key]: getValuesAsArray(value).map((item) => Number(item) || item),
        }),
        {},
    );
};

export const parseFiltersToState = (filters) => {
    const { categories, configs } = filters;

    return {
        ...getStateFromCategories({
            categories,
            upperLevelKey: PART_TYPES_LEVELS.PART_TYPES,
            nestedLevelKey: PART_TYPES_LEVELS.POSITIONS,
        }),
        ...getStateFromCategories({
            categories,
            upperLevelKey: FILTERS_LEVELS.CATEGORIES,
            nestedLevelKey: FILTERS_LEVELS.SUB_CATEGORIES,
        }),
        ...getStateFromCategories({
            categories,
            upperLevelKey: FILTERS_LEVELS.BRANDS,
            nestedLevelKey: FILTERS_LEVELS.SUB_BRANDS,
        }),
        ...getStateFromConfigs(configs),
    };
};

export const getRelatedValidGroups = ({ validGroups = [], checkedValues }) => {
    const relatedGroups = validGroups.filter((group) => {
        return Object.entries(checkedValues).every(([key, values]) => {
            const groupValue = `${group[`${key}_id`]}`;
            if (Array.isArray(values)) {
                // both group[`${key}_id`] and values could be numbers or strings
                return values.includes(groupValue) || values.includes(group[`${key}_id`]);
            }
            if (values.length) return values === groupValue;
            return true;
        });
    });
    return relatedGroups.reduce((acc, item) => {
        Object.entries(item).forEach(([key, value]) => {
            acc[key] = (acc[key] || []).concat(value);
        });
        return acc;
    }, {});
};
