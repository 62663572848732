import "./YMMSearchResultsPage.vue";
import "./PartsList.vue";
import "./PartsListItem.vue";
import "./RefineSearch.vue";

if (document.querySelector('[data-component="driv-ymm-search-results-page"]')) {
    new Vue({
        el: '[data-component="driv-ymm-search-results-page"]',
    });
}
