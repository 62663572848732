<template>
    <div class="part-number_wrapper">
        <div class="part-number-input-wrapper">
            <div class="input-wrapper">
                <form @submit.prevent="handleSearch" class="input-wrapper-form">
                    <input
                        type="text"
                        :aria-label="getTranslation('PART NUMBER')"
                        :placeholder="getTranslation('PART NUMBER')"
                        class="part-number-input"
                        v-model.trim="partNumberValue"
                    />
                </form>
                <button
                    aria-label="Arrow-button"
                    data-component="driv-part-number-search-button"
                    class="button-arrow-part-number"
                    @click="handleSearch"
                ></button>
            </div>
            <button
                data-component="driv-part-number-search-button"
                class="button-main button-down"
                @click="handleSearch"
            >
                <fmmp-i18n text="search" />
            </button>
        </div>
    </div>
</template>

<script>
export default Vue.component("driv-part-number-search", {
    data() {
        return {
            partNumberValue: "",
        };
    },
    props: {
        pagepath: {
            type: String,
            default: "",
        },
    },
    methods: {
        getTranslation(value) {
            return Vue.i18n.get(value);
        },
        handleSearch() {
            if (this.pagepath && this.partNumberValue) {
                const urlOrigin = window.location.origin;
                const redirectUrl = `${urlOrigin}${this.pagepath}.html?part=${encodeURIComponent(
                    this.partNumberValue,
                )}`;

                Vue.Global.Analytics.trackEvent(
                    "part-search",
                    "search-part-number",
                    this.partNumberValue,
                    null,
                    {
                        callback: function () {
                            window.location.assign(redirectUrl);
                        },
                    },
                );
            }
        },
    },
});
</script>
