<template>
    <div>
        <div class="part-number-page-content_table-header">
            <div class="brand-name table-header">{{ brandNameLabel }}</div>
            <div class="part-type table-header">{{ partTypeLabel }}</div>
            <div class="part-number table-header">{{ partNumberLabel }}</div>
            <div v-if="isInterchange" class="own-part-number table-header">
                {{ ownPartNumberLabel }}
            </div>
            <div class="part-ctas table-header"></div>
        </div>
        <div v-for="part in products" :key="part.part_number">
            <div class="part-number-page-content_table">
                <div class="brand-name">
                    <span class="only-mobile-label"> {{ brandNameLabel }}: </span>
                    {{ getBrandNameTranslatedForTS(part.brand_name) }}
                </div>
                <div class="part-type">
                    <span class="only-mobile-label">{{ partTypeLabel }}: </span>
                    {{ part.part_name }}
                </div>
                <div class="part-number">
                    <span class="only-mobile-label"> {{ partNumberLabel }}: </span>
                    <a v-if="!isInterchange" @click="goToDetailsHandler(part)">
                        {{ part.part_number }}
                    </a>
                    <span v-else>{{ part.part_number }}</span>
                </div>
                <div v-if="isInterchange" class="own_part_number">
                    <span class="only-mobile-label"> {{ ownPartNumberLabel }}: </span>
                    <a @click="goToDetailsHandler(part)">
                        {{ part.own_part_number }}
                    </a>
                </div>
                <div class="part-ctas">
                    <a
                        v-show="part.wtb_part_type"
                        @click="
                            () =>
                                handleWtbClick({
                                    partType: part.wtb_part_type,
                                    locType: 'install',
                                    partNumber: part.part_number,
                                })
                        "
                        class="part-number-page-content-part-cta cta-link"
                    >
                        {{ getTranslation("Get it installed") }}
                    </a>
                    <a
                        v-show="part.wtb_part_type"
                        @click="
                            () =>
                                handleWtbClick({
                                    partType: part.wtb_part_type,
                                    locType: 'store',
                                    partNumber: part.part_number,
                                })
                        "
                        class="part-number-page-content-part-cta cta-link"
                    >
                        {{ getTranslation("Buy in store") }}
                    </a>
                    <driv-dropdown-button
                        v-if="part.online_store_list && part.online_store_list.length"
                        buttonClasses="part-number-page-content-part-cta button-main button-arrow"
                        :text="getTranslation('Buy now')"
                        :options="
                            mapPartOnlineStoreToOptions(part.online_store_list, part.part_number)
                        "
                    ></driv-dropdown-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { WTB_EVENTS } from "../../../common/partFinderCorporate.constants";
import {
    mapPartOnlineStoreToOptions,
    getTranslation,
    createUrlToGoToSearchResults,
} from "../../../common/partFinderCorporate.helpers";

const TS_BRAND = "ts";

export default Vue.component("driv-part-number-result-tab", {
    props: {
        wtbPath: {
            type: String,
            default: "",
        },
        products: {
            type: Array,
            default: () => [],
        },
        isInterchange: {
            type: Boolean,
            default: false,
        },
        anyType: {
            type: String,
            default: "",
        },
        detailsPath: {
            type: String,
            default: "",
        },
    },
    computed: {
        brandNameLabel() {
            if (this.isInterchange) {
                return this.getTranslation("Mfr Name");
            }
            return this.getTranslation("Brand");
        },
        partTypeLabel() {
            return this.getTranslation("Product Type");
        },
        partNumberLabel() {
            if (this.isInterchange) {
                return this.getTranslation("OEM/Competitor Part Number");
            }
            return this.getTranslation("Part Number");
        },
        ownPartNumberLabel() {
            return this.getTranslation("Our Part Number");
        },
    },
    methods: {
        getTranslation,
        getBrandNameTranslatedForTS(brandName) {
            if (this.isInterchange) {
                return brandName;
            }

            if (window.brand === TS_BRAND) {
                return Vue.i18n.get(brandName);
            }

            return brandName;
        },
        handleGoToDetails(url) {
            Vue.Global.urlLocation.redirect(url);
        },
        // for brand sites with their own Details Page
        handleGoToBrandSiteDetails(partNumber, brandCode, path) {
            window.location.assign(
                createUrlToGoToSearchResults({
                    queryObj: { part_number: partNumber, brand_code: brandCode },
                    path,
                }),
            );
        },
        goToDetailsHandler(product) {
            Vue.Global.Analytics.trackEvent(
                "Part Search Results Link",
                "Part",
                product.part_number,
                null,
                {
                    callback: () => {
                        this.detailsPath
                            ? this.handleGoToBrandSiteDetails(
                                  product.own_part_number ? product.own_part_number : product.part_number,
                                  product.own_brand_code ? product.own_brand_code : product.brand,
                                  this.detailsPath,
                              )
                            : this.handleGoToDetails(product.part_detail_redirect_url);
                    },
                },
            );
        },
        mapPartOnlineStoreToOptions,
        getWtbUrl(partType, locType) {
            const urlOrigin = window.location.origin;
            const anyType = this.anyType;
            if (anyType == "true" || window.brand === 'walker') {
                return `${urlOrigin}${this.wtbPath}.html#locType=${locType}&partType=any&subBrand=all`;
            }
            return `${urlOrigin}${this.wtbPath}.html#locType=${locType}&partType=${partType}&subBrand=all`;
        },
        handleWtbClick({ partType, locType, partNumber }) {
            const url = this.getWtbUrl(partType, locType);

            Vue.Global.Analytics.trackEvent("where-to-buy", WTB_EVENTS[locType], partNumber, null, {
                callback: function () {
                    window.location.assign(url);
                },
            });
        },
    },
});
</script>
