<template>
  <div class="driv-part-finder-main-heading">
    <div class="driv-part-finder-main-default-text">
      <h2 class="driv-part-finder-main-default-title">
        <fmmp-i18n text="What can we help you find?" />
      </h2>
      <p class="driv-part-finder-main-default-description">
        <fmmp-i18n
          :text="`Search for ${brand}® products with our easy part finder or by your
        interchange number. Also find ${brand}® products near you with our
        store locator.`"
        />
      </p>
    </div>
  </div>
</template>

<script>
export default Vue.component("driv-part-finder-main", {
  computed: {
    brand: () => {
      const brand = window.brand;

      return brand.charAt(0).toUpperCase() + brand.substring(1);
    }
  }
});
</script>
