export const FILTERS_QS_OPTIONS = {
    strictNullHandling: true,
    arrayFormat: "comma",
    indices: false,
    comma: true,
    parseArrays: false,
    encodeValuesOnly: true,
};

export const COLUMN_NAMES = {
    IMAGE: "image",
    NAME: "name",
    SUB_MODEL: "submodel",
    DRIVE_WHEEL: "drive-wheel",
    ENGINE_BASE: "engine-base",
    ENGINE_VIN: "engine-vin",
    POSITION: "position",
    ENGINE_DESIGNATION: "engine_designation",
    QUALIFIERS: "qualifiers",
    QUANTITY: "vehicle-quantity",
    WTB: "wtb",
    UPPER_MOUNT_CODE: "Upper Mount Code",
    LOWER_MOUNT_CODE: "Lower Mount Code"
};

export const TABLE_COLUMNS = [
    { label: "", className: COLUMN_NAMES.IMAGE },
    { label: "Part", className: COLUMN_NAMES.NAME },
    { label: "Submodel", className: COLUMN_NAMES.SUB_MODEL },
    { label: "Drivewheel", className: COLUMN_NAMES.DRIVE_WHEEL },
    { label: "Engine Base", className: COLUMN_NAMES.ENGINE_BASE },
    { label: "Engine VIN", className: COLUMN_NAMES.ENGINE_VIN },
    { label: "Position", className: COLUMN_NAMES.POSITION },
    { label: "Designation", className: COLUMN_NAMES.ENGINE_DESIGNATION },
    { label: "Qualifiers", className: COLUMN_NAMES.QUALIFIERS },
    { label: "Veh. Qty", className: COLUMN_NAMES.QUANTITY },
    { label: "", className: COLUMN_NAMES.WTB },
];

export const QUALIFIERS_FILTERS_PROPERTY = "qualifiers";

export const SPECIFICATION_PROPERTIES = {
    SPECIFICATION_LIST_PROPERTY: "part_specification_list",
    MASTER_LIST_PROPERTY: "specification_attribute_master_list",
    SPECIFICATIONS_PROPERTY: "specifications",
    ATTRIBUTES_PROPERTY: "part_attributes",
    ATTRIBUTE_FILTERS: "attribute_filters",
    ATTRIBUTE_RANGE_FILTERS: "attribute_range_filters",
    UJOINT_ATTRIBUTES: "ujoint_attribute_values",
    DAM_ASSETS: "dam_assets"
};

export const CHAMPION_BRAND = "Champion";
export const MONROE_BRAND = "Monroe";
export const RANCHO_BRAND = "Rancho";

export const UOM_VALUES = {
    IN: "IN",
    MM: "MM",
};

export const SCROLL_BAR_WIDTH = 16;

export const PART_NUMBER_PROPERTY = "partNumber";

export const SPECIFICATION_MOUNT_CODE_HOVER_TEXT = "Mounting Code Table";

export const MOUNT_CODE_TABLE_PATH_LABEL = "mount-code-table-path";

export const DEFAULT_TORQUE_PDF_LINK_NAME = "Click to view PDF Guide";

// To correctly encode/decode special symbols like &#
export const SPECIFICATIONS_QS_OPTIONS = { interpretNumericEntities: true, charsetSentinel: true };

export const APPLY_RANGE_FILTER = "apply-range-filter";

// Attributes' names are correspond to part number search api response!!!
export const CHECKBOX_ATTRIBUTE_NAME = {
    products: "products",
    interchange_products: "interchange_products",
};

export const SAVED_QUICK_DETAILS_PART_KEY = "savedShowQuickDetailsKey";
