<template>
    <fmmp-modal v-if="isModalVisible" rootClass="diagrams-results-page__modal" @close="closeModal">
        <div class="modal-header"></div>
        <template slot="title">
            <div class="diagrams-results-page__modal-title">{{ title }}</div>
        </template>
        <div class="modal-table">
            <table class="diagrams-results-page__modal-content">
                <thead class="diagrams-results-page__head">
                    <th
                        v-for="column in Object.values(columns)"
                        :key="column"
                        class="diagrams-results-page__head-cell"
                    >
                        <fmmp-i18n :text="column" />
                    </th>
                </thead>
                <tbody class="diagrams-results-page__body">
                    <tr v-for="(item, i) in data" :key="item.part_number + i">
                        <td>
                            <fmmp-i18n
                                :text="columns.partNumber"
                                class="diagrams-results-page__cell-heading"
                            />
                            <a
                                @click="() => handlePartNumberClick(item.part_number, item.brand)"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                {{ transformToMissedValue(item.part_number) }}
                            </a>
                        </td>
                        <td>
                            <fmmp-i18n
                                :text="columns.partType"
                                class="diagrams-results-page__cell-heading"
                            />
                            {{ transformToMissedValue(item.part_type) }}
                        </td>
                        <td>
                            <fmmp-i18n
                                :text="columns.position"
                                class="diagrams-results-page__cell-heading"
                            />
                            {{ transformToMissedValue(item.position && item.position.value) }}
                        </td>
                        <td>
                            <fmmp-i18n
                                :text="columns.drive"
                                class="diagrams-results-page__cell-heading"
                            />
                            {{ transformToMissedValue(item.drive && item.drive.value) }}
                        </td>
                        <td>
                            <fmmp-i18n
                                :text="columns.qty"
                                class="diagrams-results-page__cell-heading"
                            />
                            {{ item.qty }}
                        </td>
                        <td>
                            <fmmp-i18n
                                :text="columns.appNotes"
                                class="diagrams-results-page__cell-heading"
                            />
                            {{ transformToMissedValue(item.additional_fit_criteria) }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="modal-footer"></div>
    </fmmp-modal>
</template>

<script>
import {
    createOverlay,
    createUrlToGoToSearchResults,
    removeOverlay,
    transformToMissedValue,
} from "../../../../common/partFinderCorporate.helpers";

export default Vue.component("driv-diagram-info-modal", {
    data() {
        return {
            columns: {
                partNumber: "part number",
                partType: "part type",
                position: "position",
                drive: "drive",
                qty: "qty",
                appNotes: "app notes",
            },
        };
    },
    props: {
        title: {
            type: String,
            default: "",
        },
        isModalVisible: {
            type: Boolean,
            default: () => false,
        },
        data: {
            type: Array,
            default: () => [],
        },
        detailsPath: {
            type: String,
            default: "",
        },
    },
    methods: {
        transformToMissedValue,
        closeModal() {
            this.$emit("toggleDiagramInfoModal");
        },
        handlePartNumberClick(partNumber, brandCode) {
            if (!this.detailsPath) return;

            const url = createUrlToGoToSearchResults({
                queryObj: { part_number: partNumber, brand_code: brandCode },
                path: this.detailsPath,
            });

            Vue.Global.Analytics.trackEvent(
                "interactive-diagrams-results",
                "click-part-number",
                partNumber,
                null,
                {
                    callback: function () {
                        window.location.assign(url);
                    },
                },
            );
        },
    },
    watch: {
        isModalVisible(isVisible) {
            isVisible ? createOverlay(this.closeModal) : removeOverlay();
        },
    },
});
</script>
