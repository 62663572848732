export const FIELDS = {
    TYPE: "specification_type",
    BRAND: "brands",
    PART_TYPE: "part_types",
    PART_DESCRIPTION: "part_description",
    TORQUE_CONTENT_SILOS: "content_silos",
};

export const ATTRIBUTES = "attribute_values";
export const ATTRIBUTES_FILTERS = "attribute_filters";
export const UJOINT_ATTRIBUTES = "ujoint_attribute_values";
export const PART_DESCRIPTION_ATTRIBUTE_NAME = "Part Description";
export const U_JOINT_ATTRIBUTE_NAME = "U-Joint Identification";
export const BRAND_TYPE_KEYS = {
    value: "brandCode",
    label: "brandName",
};
export const U_JOINT_ATTRIBUTE_VALUE = "Universal Joint";
const getInitialFieldConfig = (placeholder) => ({
    items: [],
    model: "",
    placeholder,
    disabled: true,
    loading: false,
});

export const INITIAL_FIELDS_CONFIG = {
    [FIELDS.TYPE]: getInitialFieldConfig("Specification Type"),
    [FIELDS.BRAND]: getInitialFieldConfig("Brand"),
    [FIELDS.PART_TYPE]: getInitialFieldConfig("Part Type"),
    [FIELDS.PART_DESCRIPTION]: getInitialFieldConfig("Part Description"),
    [FIELDS.TORQUE_CONTENT_SILOS]: getInitialFieldConfig("Type"),
};

export const EMPTY_ITEM = {
    value: "",
    label: "---",
};
